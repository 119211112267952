<script setup lang="ts">
const { $headerBackTo, $toast, $e } = useNuxtApp()
$headerBackTo({ routeType: 'qrhunt', name: 'overview' } as QRHuntRoute)
const log = createLog('app:qrhunt:detail')

const qrHuntStore = useQRHuntStore()
const currentRoute = computed(() => qrHuntStore.getRunningRoute)

onWSMessage<WsError>(ErrorSchema, (data) => {
    const errorMessage = $e(data.content.errorType)
    log.error(errorMessage)
})
</script>

<template>
    <div class="page-route">
        <RichText class="page-route__text rich-text--compact">
            <p class="small">{{ $t('page.qrhunt.overline') }}</p>
            <h1 v-html="currentRoute?.name" />
            <p v-html="currentRoute?.instructions?.replaceAll('{name}', currentRoute?.name)" />
        </RichText>

        <RouteList class="page-route__list">
            <CheckpointItem
                v-for="(checkpoint, _) in currentRoute?.checkpoints"
                :key="checkpoint.uid"
                :color="currentRoute?.color"
                v-bind="checkpoint"
                @click="$qrHuntRouter.push('scan')"
            />
        </RouteList>

        <ButtonGroup class="page-route__buttons">
            <!-- <AppButton
                fluid
                @click="$qrHuntRouter.push('scan')"
            >
                {{ $t('page.qrhunt.buttonLabel.qrCode') }}
            </AppButton> -->

            <AppButton
                fluid
                variant="outlined"
                @click="$qrHuntRouter.push('cancel')"
            >
                {{ $t('page.qrhunt.buttonLabel.cancelRoute') }}
            </AppButton>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-route {
    height: 100%;
    display: grid;
    grid-template-rows: auto 32px auto minmax(86px, 1fr) auto;
    grid-template-areas:
        'text   '
        '.      '
        'list   '
        '.      '
        'buttons';

    &__text {
        grid-area: text;
    }

    &__list {
        grid-area: list;
        .checkpoint-item--current ~ .checkpoint-item {
            pointer-events: none;

            .list-item__post-icon {
                display: none;
            }
        }
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
